import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

class Home extends React.Component {
  render() {
    var id = ''
    let linkMB = 'assets/files/XOSOPRO_6886_1802.apk'
    let link3M = 'assets/3M136/DL001_152.apk'
    let videoMB = '/mienbac'
    let video3M = '/ba-mien'
    if (this.props.id) {
      id = this.props.id.toLowerCase()
      videoMB = videoMB + '/' + id
      video3M = video3M + '/' + id
    }
    switch (id) {
      case '0989243804':
        linkMB = 'assets/files/BIGWIN_0989243804_1802.apk'
        break
      case '0702324352': //mb and 3m003
        linkMB = 'assets/files/LOTOVIP_0702324352_1802.apk'
        link3M = 'assets/3M136/DL003_152.apk'
        break
      case '999999':
        linkMB = 'assets/files/TINTUC24H_999999_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '0962691695':
        linkMB = 'assets/files/XSTD_0962691695_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '0395389322':
        linkMB = 'assets/files/BAOMOI_0395389322_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '8679':
        linkMB = 'assets/files/BAOMOI_8679_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '666888':
        linkMB = 'assets/files/TINTUC24H_6886_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '0567661988':
        linkMB = 'assets/files/Holika_0567661988_1802.apk'
        // link3M = 'assets//3M136/DL001.apk'
        break
      case '3m002': //3M002
        // linkMB = 'assets/files/1780/Holika_0567661988_1782.apk'
        link3M = 'assets/3M136/DL002_152.apk'
        break
      // case '3m006': //3M006
      //   // linkMB = 'assets/files/1780/Holika_0567661988_1782.apk'
      //   link3M = 'assets/3M136/DL006_136.apk'
      //   break
      default:
        break
    }

    return (
      <div className="App">
        {/* NAVBAR */}
        <nav
          id="navbar"
          className="navbar fixed-top navbar-expand-lg navbar-header navbar-mobile"
        >
          <div className="navbar-container container">
            {/* LOGO */}
            <div className="navbar-brand">
              <a className="navbar-brand-logo" href="#top">
                <img src="assets/images/phanmemtinhbang.com.png" />
              </a>
            </div>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            ></div>
          </div>
        </nav>
        {/* SECTION LABEL */}
        <div id="top"></div>
        {/* WRAPPER */}
        <div className="wrapper">
          <div className="header">
            <div className="container header-container padding0px">
              <div className="col-lg-6 header-img-section padding0px">
                <img src="assets/images/mienbac.gif" />
              </div>
              <div className="col-lg-6 header-title-section">
                <h1 className="header-title">
                  XOSOPRO Phần mềm tính bảng Miền Bắc
                </h1>
                <p className="header-title-text">
                  Dành cho cách chơi theo kiểu miền bắc
                </p>
                <div className="learn-more-btn-section">
                  {/* <a className="nav-link learn-more-btn" href="http://www.xosopro.us">Video Hướng Dẫn</a> */}
                  <Link to={videoMB} className="nav-link learn-more-btn">
                    Video Hướng Dẫn
                  </Link>
                  <a
                    className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href={linkMB}
                  >
                    <i className="fa fa-android" aria-hidden="true"></i> Tải
                    phần mềm MB
                  </a>
                </div>
                <div className="learn-more-btn-section">
                  <a
                    className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/ZALO-21-01-01-1.apk"
                  >
                    <i className="fa fa-android" aria-hidden="true"></i> Tải
                    Zalo bản cũ
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="header" style={{ padding: '10px 0 100px 0' }}>
            <div className="container header-container padding0px">
              <div className="col-lg-6 header-img-section padding0px">
                <img src="assets/images/bamien.gif" />
              </div>
              <div className="col-lg-6 header-title-section">
                <h1 className="header-title">
                  KẾ TOÁN 3M Phần mềm tính bảng 3 Miền
                </h1>
                <p className="header-title-text">
                  Dành cho cách chơi theo kiểu miền Nam
                </p>
                <div className="learn-more-btn-section">
                  <Link to={video3M} className="nav-link learn-more-btn">
                    Video Hướng Dẫn
                  </Link>

                  {/* <a className="nav-link learn-more-btn" href="http://www.xosopro.us">Video Hướng Dẫn</a> */}
                  <a
                    className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href={link3M}
                  >
                    <i className="fa fa-android" aria-hidden="true"></i> Tải
                    phần mềm 3M
                  </a>
                </div>
                <div className="learn-more-btn-section">
                  <a
                    className="nav-link learn-extra-btn"
                    target="_self"
                    download
                    href="assets/files/ZALO-21-01-01-1.apk"
                  >
                    <i className="fa fa-android" aria-hidden="true"></i> Tải
                    Zalo bản cũ
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* TUTORS */}
          <div className="tutors-section">
            <div className="tutors-section-bg-graphics-section">
              <img src="assets/images/tutors-section-bg-img.png" />
            </div>
            <div className="container tutors-container">
              <div className="pricing-title">
                <h2>Liên hệ</h2>
                <p>
                  {' '}
                  Phần mềm được cung cấp dùng thử miễn phí dài hạn. Vui lòng
                  liên hệ để được hướng dẫn sử dụng.
                </p>
              </div>
              <div className="container tutors-img-container">
                <div className="col-lg-2 col-md-6 col-sm-6 col-6 tutors-card-section">
                  <div className="tutors-card">
                    <div className="tutors-card-icon-section">
                      {/* <img src="assets/images/tutor1.svg" /> */}
                      <i
                        className="fa fa-telegram"
                        aria-hidden="true"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                    <h2>
                      {/* <a href="tel:0981715186">0981.715.186</a> */}
                      <a href="https://t.me/phanmemxoso" target="_blank">
                      Telegram 24/7 </a>
                    </h2>
                    
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6 col-6 tutors-card-section">
                  <div className="tutors-card">
                    <div className="tutors-card-icon-section">
                      <i
                        className="fa fa-facebook"
                        aria-hidden="true"
                        style={{ fontSize: '70px' }}
                      ></i>
                    </div>
                    <h2>
                      <a href="https://www.facebook.com/100011505182631">
                        Hà Đức Tuấn
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="header" style={{ paddingTop: '20px' }}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
